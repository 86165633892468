<template>
    <nav>
        <a href="/">
            <img style="max-width: 150px" :src="imgLogo" alt="Logo HANS">
        </a>

        <a href="#" class="d-block d-lg-none">
            <img style="max-width: 150px" id="hamburgerMobile" class="menu" :src="imgMenu" @click="toggleMenu" alt="Hauptmenü">
        </a>
        <a href="#" class="d-none d-lg-block">
            <img style="max-width: 150px" id="hamburger" class="menu" :src="imgMenu" @mouseenter="toggleMenu" alt="Hauptmenü">
        </a>
    </nav>
</template>

<script>
    import {EventBus} from '../eventbus.js';

    export default {
        name: "hans-nav",
        props: {
            imgLogo: String,
            imgMenu: String,
            isMobile : Boolean
        },
        data: function () {
            return {
                menuClosed: true
            }
        },
        methods: {
            toggleMenu: function () {
                this.menuClosed = !this.menuClosed;
                console.log( 'Closed: ' + this.menuClosed );
                EventBus.$emit( 'menu-toggle', this.menuClosed );
            }
        },
        created() {
            EventBus.$on('menu-toggle', menuClosed => {
                this.menuClosed = menuClosed;
            });
        }
    }
</script>

<style scoped>

</style>