<template>

    <nav id="menu" :class="{ gone : menuClosed }" @mouseleave="isMobile ? null : toggleMenu()">

        <a class="d-block" href="#" @click.prevent="toggleMenu()">
            <img class="menu" style="max-width: 150px" :src="imgClose" alt="Menü schließen">
        </a>

        <ul>
            <li>
                <a class="fetch" href="/" @click.prevent="fetch( 'home' ); isMobile ? toggleMenu() : null">Home</a>
            </li>
            <li>
                <a class="fetch" href="/leistungen" @click.prevent="fetch( 'leistungen' ); isMobile ? toggleMenu() : null">Leistungen</a>
            </li>
            <li>
                <a class="fetch" href="/technologien" @click.prevent="fetch( 'technologien' ); isMobile ? toggleMenu() : null">Technologien</a>
            </li>
            <li>
                <a class="fetch" href="/referenzen" @click.prevent="fetch( 'referenzen' ); isMobile ? toggleMenu() : null">Referenzen</a>
            </li>
            <li>
                <a class="fetch" href="/kundenstimmen" @click.prevent="fetch( 'kundenstimmen' ); isMobile ? toggleMenu() : null">Kundenstimmen</a>
            </li>
            <li>
                <a class="fetch" href="/ueber" @click.prevent="fetch( 'ueber' ); isMobile ? toggleMenu() : null">Über HANS</a>
            </li>
            <li>
                <a class="fetch" href="/kontakt" @click.prevent="fetch( 'kontakt' ); isMobile ? toggleMenu() : null">Kontakt</a>
            </li>
        </ul>

    </nav>

</template>

<script>
    import {EventBus} from '../eventbus.js';

    export default {
        name: "hans-menu",
        props: {
            imgClose: String,
            baseurl: String,
            isMobile : Boolean
        },
        data: function () {

            return {
                menuClosed: true
            }
        },
        methods: {
            toggleMenu: function () {

                this.menuClosed = !this.menuClosed;
                console.log( 'Closed: ' + this.menuClosed );
                EventBus.$emit( 'menu-toggle', this.menuClosed );
            },
            fetch: function ( path, pushState = true ) {

                let fetchurl = this.baseurl + '/fetch/' + path;

                fetch( fetchurl, {

                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    }

                } )
                    .then( function ( res ) {

                        return res.json();
                    } )
                    .then( function ( res ) {

                        // Replace #content
                        let frag = document.createRange().createContextualFragment( res.content );
                        let orig = document.getElementById( 'content' );
                        orig.parentNode.replaceChild( frag, orig );

                        // Push history, if this is not a back/forward browser navigation event
                        if ( pushState == true ) {
                            window.history.pushState( path, null, '/' + path );
                        }

                    } )
                    .catch( function ( err ) {

                        console.log( 'Error: ' + err );

                    } )

            }
        },
        created : function() {

            EventBus.$on( 'menu-toggle', menuClosed => {
                this.menuClosed = menuClosed;
            } );

            const comp = this;
            window.onpopstate = function ( event ) {
                if ( event.state !== null ) {

                    comp.fetch( event.state.replace( /[^a-zA-Z]/g, '' ), false );
                }
            };
        }
    }
</script>

<style scoped>

</style>