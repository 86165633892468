var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      class: { gone: _vm.menuClosed },
      attrs: { id: "menu" },
      on: {
        mouseleave: function($event) {
          _vm.isMobile ? null : _vm.toggleMenu()
        }
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "d-block",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleMenu()
            }
          }
        },
        [
          _c("img", {
            staticClass: "menu",
            staticStyle: { "max-width": "150px" },
            attrs: { src: _vm.imgClose, alt: "Menü schließen" }
          })
        ]
      ),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("home")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Home")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/leistungen" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("leistungen")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Leistungen")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/technologien" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("technologien")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Technologien")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/referenzen" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("referenzen")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Referenzen")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/kundenstimmen" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("kundenstimmen")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Kundenstimmen")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/ueber" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("ueber")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Über HANS")]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "fetch",
              attrs: { href: "/kontakt" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.fetch("kontakt")
                  _vm.isMobile ? _vm.toggleMenu() : null
                }
              }
            },
            [_vm._v("Kontakt")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }