var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticStyle: { "max-width": "150px" },
        attrs: { src: _vm.imgLogo, alt: "Logo HANS" }
      })
    ]),
    _vm._v(" "),
    _c("a", { staticClass: "d-block d-lg-none", attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu",
        staticStyle: { "max-width": "150px" },
        attrs: { id: "hamburgerMobile", src: _vm.imgMenu, alt: "Hauptmenü" },
        on: { click: _vm.toggleMenu }
      })
    ]),
    _vm._v(" "),
    _c("a", { staticClass: "d-none d-lg-block", attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "menu",
        staticStyle: { "max-width": "150px" },
        attrs: { id: "hamburger", src: _vm.imgMenu, alt: "Hauptmenü" },
        on: { mouseenter: _vm.toggleMenu }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }