/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require( './bootstrap' );

window.Vue = require( 'vue' );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component( 'HansNav', require( './components/HansNav.vue' ).default );
//Vue.component( 'HansMenu', require( './components/HansMenu.vue' ).default );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import HansNav from './components/HansNav.vue';
import HansMenu from './components/HansMenu.vue';

const app = new Vue( {
    el: '#app',
    components : {
        HansNav,
        HansMenu
    },
    data : function() {
        return {
            baseurl : '',
            isMobile: false
        };
    },
    methods : {
        detectMobile: function () {
            this.isMobile = window.matchMedia( '(max-width: 991px)' ).matches;
        }
    },
    mounted: function () {
        this.baseurl = this.$el.attributes.Baseurl.value;
    },
    created : function() {
        this.detectMobile();
        window.addEventListener( 'resize', this.detectMobile )
    },
    beforeDestroy : function() {
        window.removeEventListener( 'resize', this.detectMobile )
    }
} );
